import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';

export default function NotFound(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='flex h-screen w-screen flex-col items-center justify-center'>
            <p className='max-w-lg text-center text-3xl text-companyLightText'>{getText('pages.404.headline')}</p>
        </div>
    );
}
