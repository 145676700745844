import React, { ReactElement } from 'react';

type Props = {
    id: string;
    width: string;
    height: string;
    url: string;
};
export default function PreviewImage(props: Props): ReactElement {
    const { id, width, height, url } = props;

    return (
        <div style={{ width, height }} className='shadow shadow-gray-500'>
            <img width={width} height={height} alt={`Preview ${id}`} src={url} />
        </div>
    );
}
