import { gql, useQuery } from '@apollo/client';
import { Rendering as RenderingGraphQL, RenderingResult } from '../../generated/types/graphql-types';
import { Rendering } from '../types/rendering';
import { useEffect, useState } from 'react';
import usePreview from './use-preview';
import { RenderingExportTarget } from '../types/enums/rendering';

type RenderJobApi = {
    loading: boolean;
    renderings?: Rendering[];
    name?: string;
    createdAt?: Date;
    expiredAt?: Date;
};

const GET_RENDERING_JOB = gql`
    query RenderingJob($renderingJobId: ID!) {
        renderingJob(id: $renderingJobId) {
            name
            start
            result {
                ... on RenderingJobSuccess {
                    availableUntil
                }
            }
            renderings {
                id
                result {
                    ... on RenderingResult {
                        previewUrl
                    }
                    ... on RenderingError {
                        message
                    }
                }
                renderingFormat {
                    name
                    height
                    width
                }
                exportTarget
                displayName
            }
        }
    }
`;

type Props = {
    id: string;
};

export default function useRenderingJob(props: Props): RenderJobApi {
    const { id } = props;

    const [isLoading, setIsloading] = useState(true);
    const { renderings, setRenderings, setExpireAt, setName, setCreatedAt } = usePreview();

    const { loading, data } = useQuery(GET_RENDERING_JOB, {
        fetchPolicy: 'no-cache',
        variables: {
            renderingJobId: id,
        },
    });

    useEffect(() => {
        if (loading) {
            return;
        }

        const parsedRenderings = data?.renderingJob.renderings.map((r: RenderingGraphQL) => {
            const { result, exportTarget, displayName, renderingFormat } = r;

            return {
                id: r.id,
                format: {
                    name: renderingFormat?.name || '',
                    height: renderingFormat?.height || '',
                    width: renderingFormat?.width || '',
                },
                exportTarget,
                displayName: displayName || '',
                result:
                    // eslint-disable-next-line no-underscore-dangle
                    !result || result.__typename === 'RenderingError'
                        ? undefined
                        : { url: (result as RenderingResult).previewUrl },
            };
        });

        const out = parsedRenderings
            ? parsedRenderings.filter(
                  (item: Rendering) =>
                      item.exportTarget === RenderingExportTarget.Png ||
                      item.exportTarget === RenderingExportTarget.Mp4 ||
                      item.exportTarget === RenderingExportTarget.GoogleAd,
              )
            : [];

        setIsloading(false);
        setRenderings(out);
        setName(data?.renderingJob.name);
        setCreatedAt(data?.renderingJob.start);
        setExpireAt((data?.renderingJob.result as RenderingResult).availableUntil);
    }, [data, loading]);

    return {
        loading: isLoading,
        renderings,
    };
}
