import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import App from './app';
import GraphqlProvider from './providers/graphql-provider';
import PreviewProvider from './providers/preview-provider';

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
root.render(
    <React.StrictMode>
        <GraphqlProvider>
            <PreviewProvider>
                <App />
            </PreviewProvider>
        </GraphqlProvider>
    </React.StrictMode>,
);
