import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { getEnv } from '../utils/environment-variables';

const cache = new InMemoryCache();

let GraphQlClient = new ApolloClient({ cache });

type Props = {
    children: ReactNode;
};

export default function GraphqlProvider(props: Props): ReactElement {
    const { children } = props;
    const [graphQlInitialized, setGraphQlInitialized] = useState(false);

    useEffect(() => {
        const httpLink = new HttpLink({
            uri: getEnv('REACT_APP_MAGNOLIA_BACKEND_URL'),
        });

        const authLink = setContext((_, { headers }) => ({
            headers: {
                ...headers,
                authorization: `Bearer ${getEnv('REACT_APP_MAGNOLIA_BACKEND_API_TOKEN')}`,
                'Apollo-Require-Preflight': 'true',
            },
        }));

        GraphQlClient = new ApolloClient({
            cache,
            link: authLink.concat(httpLink),
        });

        setGraphQlInitialized(true);
    }, [GraphQlClient]);

    if (graphQlInitialized) {
        return <ApolloProvider client={GraphQlClient}>{children}</ApolloProvider>;
    }

    return <div />;
}
