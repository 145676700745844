import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

export default function Layout(): ReactElement {
    return (
        <main className='h-screen w-screen'>
            <Outlet />
        </main>
    );
}
