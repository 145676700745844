import { useCallback, useState } from 'react';
import TextContainer from '../locals/locals';
import { Locale } from '../types/enums/locale';

export type Local = {
    locale: string;
    // eslint-disable-next-line no-unused-vars
    getText(key: string, ...values: Array<any>): string;
    // eslint-disable-next-line no-unused-vars
    setLocale(locale: Locale, reload?: boolean): void;
};

const initialLocale = (localStorage.getItem('locale') === 'de' && 'de') || 'en';

export default function useLocale(): Local {
    const [locale, setLocale] = useState<string>(initialLocale);

    function onGetText(key: string, ...values: Array<any>): string {
        // @ts-ignore
        return TextContainer.formatString(TextContainer.getString(key, locale), ...values);
    }

    function onSetLocale(newLocale: Locale, reload?: boolean): void {
        setLocale(newLocale);
        localStorage.setItem('locale', newLocale);

        if (reload) {
            window.location.reload();
        }
    }

    const getTextCallback = useCallback(onGetText, [locale]);
    const setLocalCallback = useCallback(onSetLocale, []);

    return {
        locale: locale || Locale.EN,
        getText: getTextCallback,
        setLocale: setLocalCallback,
    };
}
