import React, { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react';
import Class from '../../utils/classes';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
    icon: ReactNode;
    iconPosition?: 'left' | 'right';
    tooltip?: string;
};

export default function TextIconButton(props: Props): ReactElement {
    const { label, icon, iconPosition, disabled, tooltip, ...rest } = props;

    return (
        <button
            {...rest}
            className={Class.classNames(
                'group relative flex flex-row items-center space-x-3 rounded-2xl bg-company px-6 py-2',
                disabled
                    ? 'pointer-events-none cursor-default [&>svg]:fill-companyGrey'
                    : 'cursor-pointer hover:opacity-80 [&>svg]:fill-white',
            )}
            type='button'
        >
            {iconPosition === 'left' && (
                <>
                    {icon}
                    <div className={Class.classNames('h-4 w-px', disabled ? 'bg-companyGrey' : 'bg-white')} />
                </>
            )}
            <p
                className={Class.classNames(
                    'whitespace-nowrap text-base',
                    disabled ? 'text-companyGrey' : 'text-white',
                )}
            >
                {label}
            </p>
            {iconPosition === 'right' && (
                <>
                    <div className={Class.classNames('h-4 w-px', disabled ? 'bg-companyGrey' : 'bg-white')} />
                    {icon}
                </>
            )}
            {tooltip && (
                <span
                    className={Class.classNames(
                        'absolute -top-5 left-1/2 z-50 !ml-0 -translate-x-1/2 scale-0  whitespace-nowrap rounded bg-companyMediumGrey p-1 text-xs text-white group-focus:scale-100',
                    )}
                >
                    {tooltip}
                </span>
            )}
        </button>
    );
}

TextIconButton.defaultProps = {
    iconPosition: 'right',
    tooltip: '',
};
