/**
 * getEnv retrieves the value of the environment variable named by the key.
 * @param key environment variable name
 */
export function getEnv(key: string): string {
    const value = process.env[key];
    if (!value) {
        throw new Error(`failed to get environment variable ${key}`);
    }

    return value;
}

const os = {
    getEnv,
};

export default os;
