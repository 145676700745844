import React, { ReactElement, useEffect, useState } from 'react';
import TextIconButton from '../button/text-icon-button';
import LinkIcon from '../icons/outline/link';
import usePreview from '../../hooks/use-preview';
import moment from 'moment';
import Class from '../../utils/classes';
import useLocale from '../../hooks/use-locale';

export default function JobPreviewHeader(): ReactElement {
    const { name, expireAt, createdAt } = usePreview();
    const [top, setTop] = useState(true);

    const { getText } = useLocale();

    useEffect(() => {
        function scrollHandler(): void {
            if (window.pageYOffset > 10) {
                setTop(true);
            } else {
                setTop(false);
            }
        }

        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    function onCopyUrl(): void {
        navigator.clipboard.writeText(window.location.href);
    }

    return (
        <header
            className={Class.classNames('fixed z-10 w-screen rounded-b-3xl bg-white px-16 py-8', top ? 'shadow' : '')}
        >
            <div className='mb-8 flex flex-row justify-between'>
                <div className='flex flex-col justify-between'>
                    <h1 className='text-3xl text-companyLightText'>{name}</h1>
                    <p className='font-roboto text-1xl text-companyLightText'>
                        {getText('components.preview.header.createdAt', {
                            date: moment(createdAt).format('DD.MM.YYYY'),
                        })}
                    </p>
                </div>
                <div className='flex flex-col justify-between'>
                    <p className='px-6 py-4 text-right font-roboto text-2xl text-companyLightText '>
                        {getText('components.preview.header.expireAt')}
                    </p>
                    <p className='pr-6 text-right text-1xl text-companyLightText'>
                        {moment(expireAt).format('DD.MM.YYYY')}
                    </p>
                </div>
            </div>
            <TextIconButton
                onClick={() => onCopyUrl()}
                icon={<LinkIcon width={19} height={19} />}
                label={getText('components.preview.header.copy.label')}
                tooltip={getText('components.preview.header.copy.tooltip')}
            />
        </header>
    );
}
