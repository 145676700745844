import React, { ReactElement } from 'react';

type Props = {
    width: string;
    height: string;
    url: string;
};

export default function PreviewVideo(props: Props): ReactElement {
    const { width, height, url } = props;

    return (
        <div style={{ width, height }} className='shadow shadow-gray-500'>
            <video className='max-w-max' height={height} width={width} controls>
                <source src={url} type='video/mp4' />
                <track kind='captions' />
            </video>
        </div>
    );
}
