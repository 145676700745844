import React, { createContext, ReactElement, useMemo, useState } from 'react';
import { Rendering } from '../types/rendering';

type PreviewProviderContextType = {
    renderingJobId?: string;
    setRenderingJobId(id: string): void;

    name?: string;
    setName(n: string): void;

    createdAt?: string;
    setCreatedAt(t: string): void;

    expireAt?: string;
    setExpireAt(t: string): void;

    renderings?: Rendering[];
    setRenderings(r: Rendering[]): void;
};

export const PreviewContext = createContext<PreviewProviderContextType>({
    renderingJobId: undefined,
    setRenderingJobId: () => {},

    name: undefined,
    setName: () => {},

    createdAt: undefined,
    setCreatedAt: () => {},

    expireAt: undefined,
    setExpireAt: () => {},

    renderings: undefined,
    setRenderings: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function PreviewProvider(props: Props): ReactElement {
    const { children } = props;

    const [renderingJobId, setRenderingJobId] = useState<string>();
    const [name, setName] = useState<string>();
    const [createdAt, setCreatedAt] = useState<string>();
    const [expireAt, setExpireAt] = useState<string>();
    const [renderings, setRenderings] = useState<Rendering[]>();

    const value = useMemo(
        () => ({
            renderingJobId,
            setRenderingJobId,

            name,
            setName,

            createdAt,
            setCreatedAt,

            expireAt,
            setExpireAt,

            renderings,
            setRenderings,
        }),
        [renderings, createdAt, expireAt, name, renderingJobId],
    );

    return <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>;
}
