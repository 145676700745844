import React, { ReactElement } from 'react';

type Props = {
    title: string;
    width: string;
    height: string;
    url: string;
};

export default function PreviewBanner(props: Props): ReactElement {
    const { title, width, height, url } = props;
    return (
        <div style={{ width, height }} className='shadow shadow-gray-500'>
            <iframe src={url} height={height} width={width} title={title} />
        </div>
    );
}
