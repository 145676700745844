import React, { ReactElement } from 'react';
import { Rendering } from '../../types/rendering';
import Divider from '../../layout/divider';
import PreviewImage from './preview-image';
import { RenderingExportTarget } from '../../types/enums/rendering';
import PreviewVideo from './preview-video';
import PreviewBanner from './preview-banner';
import useLocale from '../../hooks/use-locale';

type Props = {
    item: Rendering;
};
export default function JobPreviewItem(props: Props): ReactElement {
    const { item } = props;

    const { getText } = useLocale();

    return (
        <div className='w-full'>
            <div className='py-16'>
                <div className='overflow-x-scroll pb-7 pl-16 pr-16 pt-7'>
                    {item.exportTarget === RenderingExportTarget.Png && (
                        <PreviewImage
                            id={item.id}
                            width={item.format.width}
                            height={item.format.height}
                            url={item.result!.url}
                        />
                    )}
                    {item.exportTarget === RenderingExportTarget.Mp4 && (
                        <PreviewVideo width={item.format.width} height={item.format.height} url={item.result!.url} />
                    )}
                    {item.exportTarget === RenderingExportTarget.GoogleAd && (
                        <PreviewBanner
                            title={item.displayName}
                            width={item.format.width}
                            height={item.format.height}
                            url={item.result!.url}
                        />
                    )}
                </div>
                <div className='px-16'>
                    <p className='mb-3 font-bold'>{item.displayName}</p>
                    <p className='text-sm'>
                        {getText('components.preview.item.format', {
                            width: item.format.width,
                            height: item.format.height,
                        })}
                    </p>
                </div>
            </div>
            <div className='w-full px-10'>
                <Divider />
            </div>
        </div>
    );
}
