import React, { ReactElement } from 'react';
import usePreview from '../../hooks/use-preview';
import useRenderingJob from '../../hooks/use-rendering-job';
import JobPreviewHeader from './job-preview-header';
import JobPreviewList from './job-preview-list';
import { useNavigate } from 'react-router-dom';
import LoadingIcon from '../icons/outline/loading';
import Colors from '../../types/colors';

export default function JobPreview(): ReactElement {
    const { jobId } = usePreview();
    const { loading, renderings } = useRenderingJob({ id: jobId! });

    const navigate = useNavigate();

    if (loading) {
        return (
            <div className='flex h-full w-full items-center justify-center'>
                <LoadingIcon lineColor={Colors.company.primary} />
            </div>
        );
    }

    if (!renderings || renderings.length === 0) {
        navigate('/404');
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    return (
        <>
            <JobPreviewHeader />
            <JobPreviewList renderings={renderings} />
        </>
    );
}
