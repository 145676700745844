import { useContext } from 'react';
import { PreviewContext } from '../providers/preview-provider';
import { Rendering } from '../types/rendering';

type PreviewApi = {
    jobId?: string;
    setJobId(id: string): void;

    name?: string;
    setName(n: string): void;

    createdAt?: string;
    setCreatedAt(t: string): void;

    expireAt?: string;
    setExpireAt(t: string): void;

    renderings?: Rendering[];
    setRenderings(r: Rendering[]): void;
};

export default function usePreview(): PreviewApi {
    const {
        renderingJobId,
        setRenderingJobId,
        expireAt,
        setExpireAt,
        name,
        setName,
        createdAt,
        setCreatedAt,
        renderings,
        setRenderings,
    } = useContext(PreviewContext);

    return {
        jobId: renderingJobId,
        setJobId: setRenderingJobId,
        expireAt,
        setExpireAt,
        name,
        setName,
        createdAt,
        setCreatedAt,
        renderings,
        setRenderings,
    };
}
