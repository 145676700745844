import React, { ReactElement, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import usePreview from '../hooks/use-preview';
import JobPreview from '../components/preview/job-preview';

export default function Home(): ReactElement {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { setJobId } = usePreview();

    const renderingJobId = searchParams.get('renderingJobId');

    useEffect(() => {
        if (!renderingJobId) {
            navigate('/404');
            return;
        }
        setJobId(renderingJobId);
    }, [renderingJobId]);

    return <JobPreview />;
}
