import React, { ReactElement } from 'react';
import JobPreviewItem from './job-preview-item';
import { Rendering } from '../../types/rendering';

type Props = {
    renderings: Rendering[];
};

export default function JobPreviewList(props: Props): ReactElement {
    const { renderings } = props;

    return (
        <div className='pt-56'>
            {renderings.map((r) => (
                <JobPreviewItem key={r.id} item={r} />
            ))}
        </div>
    );
}
